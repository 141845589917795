// Variables
$header-size: 4em;
$header-padding: 2em;
$player-padding: 2 * $header-padding;
$footer-padding: $header-padding;

/*
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV.
*/
$breakpoint-xl: 1440px;
$breakpoint-lg: 1200px;
$breakpoint-md: 1024px;
$breakpoint-sm: 768px;
$breakpoint-xs: 480px;

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .Header {
    padding: $header-padding;

    svg {
      $offset: 0.3em; // Offset of logo relative to font

      height: 1em + $offset;
      transform: translate(0, $offset);
      position: relative;
      margin-right: 0.35em;
      margin-top: -$offset;
    }

    h1 {
      font-size: 4em;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 0.01em;
    }
  }

  .Body {
    // Scrolling body
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding: $player-padding;

    .Player {
      width: 100%;
      height: 100%;
      
      @media screen and (min-width: $breakpoint-xl) {
        width: 25%;
      }

      @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1) {
        width: 33%;
      }

      @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
        width: 40%;
      }

      @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
        width: 50%;
      }

      @media screen and (max-width: $breakpoint-sm - 1) {
        width: 100%;
      }
    }

    .News {

    }
  }

  .Footer {
    padding: $footer-padding;
    background-color: rgb(0 0 0 / 0.4);
    overflow-x: hidden;

    .Link {
      display: inline-flex;
      align-items: center;
      margin-right: 1em;
      
      a {
        margin-right: 0.6ex;
        position: relative;
        transition: .2s;

        &:hover {
          color: hsl(210deg 75% 75% / 100%);
          top: -1px;
          left: -1px;
          filter: drop-shadow(2px 3px 3px black);
        }
        
        svg {
          position: relative;
          top: .15em;
          margin-right: 0.4ex;
        }
      }
    }
  }
}
