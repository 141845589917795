@import-normalize;
@font-face {
  font-family: 'Walkway';
  src: url('fonts/Walkway_Black.ttf') format('truetype');
}

h1, h2, h3, h4 {
  font-family: 'Walkway';
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: white;

  background: url('img/background@2x.jpg') black no-repeat center center / cover;
  background-size: cover; /* for Safari */

  height: 100vh;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: white;
}